const staticStrings = {
  GDPRCompliant:
    "Effective May 2018 our applications are GDPR compliant. We do not share your data with any third party except as mandated by Canadian Laws which requires certain data collection of foreign nationals who donate to organizations in India. These details are to be filed with the government and also uploaded in public domain.",
  mobileNoHint:
    "Phone Number should be in this format +(Country code)(phone number) e.g. +16622335588 for US, +919988776655 for India.",
  addressLineHint:
    "Full address mandatory for donation receipt. If address not provided fully, Organization needs to pay 30% tax on these donations.",
  txnIdNoHint: "PAN Number mandatory to claim tax benefit u/s 80G in India",
  greenWidget: [
    {
      imgSrc: "./imgs/Donate_Yatre.png",
      widgetContent:
        "A landscaped forest depicting the spiritual chakras and journey of humankind.",
    },
    {
      imgSrc: "./imgs/Donate_Food.png",
      widgetContent:
        "Creating a self-sustaining forest ecosystem of ecologically and economically important food/fruit tree species.",
    },
    {
      imgSrc: "./imgs/Donate_rain.png",
      widgetContent:
        "Curating rare and endangered species for dense mini-forests in the dry region of Telangana.",
    },
    {
      imgSrc: "./imgs/Deccan.png",
      widgetContent:
        "Selection of hardy drought resistant species of Deccan Peninsula to revive and propagate red-listed and endemic species of great conservation significance.",
    },
  ],
  generalDonationWidget: [
    {
      imgSrc: "./imgs/covid-voice.png",
      tittle: "‘Voice That Cares’ Helpline:",
      widgetContent:
        "<p>Recognizing that crisis-panic and prolonged isolation can cause anxiety and reduce immunity, we have launched ‘Voice That Cares’, a 24×7 Helpline to help alleviate pandemic stress.The helpline can be accessed by dialing the toll-free number: 1800 121 3492 Dhyana.</p><p>The helpline is backed by several hundred certified Heartfulness Trainers. Their job is to respond to public calls and help relieve the emotional or mental distress of callers.</p>",
    },
    {
      imgSrc: "./imgs/covid-webinar.png",
      tittle: "Medical Programmes:",
      widgetContent:
        "<p>Noticing the need for precautionary and preventive interventions in hygiene and lifestyle, our Heartfulness Continuing Medical Education team is undertaking various virtual awareness programmes. Our team of healthcare experts are volunteering their time online, to provide psychological counselling and informative webinars</p>",
    },
    {
      imgSrc: "./imgs/covid-mobile.png",
      tittle: "Ongoing Heartfulness Practices:",
      widgetContent:
        "<p>Our network of thirteen thousand trainers, under the guidance of Daaji, are working with millions of practitioners around the world to provide virtual training programmes in the Heartfulness Practices. These include programmes on Relaxation, Meditation, Mental Detoxing and Rejuvenation, and Connecting Within. These sessions are available 24×7 every day on the HeartsApp or in other online meeting platforms.</p>",
    },
  ],
  userProfiles: [
    { userName: "Ramesh", citizenship: "India", address: "New st1" },
    { userName: "Kumar", citizenship: "India", address: "New st2" },
    { userName: "Arun", citizenship: "India", address: "New st3" },
  ],
  donationTypes: [
    { online: "Online Donations" },
    { offline: "Offline modes of Donation" },
    { recurring: "Recurring Donations" },
  ],
  donationCountry: [
    "India",
    "United States",
    "Europe",
    "France",
    "Canada",
    "Kenya",
    "Others",
  ],
  coperateGiftMatching: [
    {
      company: "ADP employee giving and matching",
      checkers: [true, true, true],
    },
    {
      company: "ADP Inc.",
      checkers: [false, true, true],
    },
    {
      company: "American Endowment Fund (Benevity)",
      checkers: [true, true, true],
    },
    {
      company: "American Express",
      checkers: [true, true, true],
    },
    {
      company: "Anthem",
      checkers: [false, false, true],
    },
    {
      company: "Apple Inc.",
      checkers: [false, true, true],
    },
    {
      company: "ARM",
      checkers: [false, false, true],
    },
    {
      company: "Assurant",
      checkers: [true, true, false],
    },
    {
      company: "AT&T",
      checkers: [true, true, true],
    },
    {
      company: "Atlassian",
      checkers: [false, false, true],
    },
    {
      company: "Bank of America",
      checkers: [true, true, true],
    },
    {
      company: "Bank of the West",
      checkers: [true, false, true],
    },
    {
      company: "Blackrock Inc.",
      checkers: [false, true, true],
    },
    {
      company: "Charles Schwab & Co., Inc.",
      checkers: [false, false, true],
    },
    {
      company: "CHUBB & SON",
      checkers: [true, false, false],
    },
    {
      company: "Ciena",
      checkers: [false, false, true],
    },
    {
      company: "Constellation Energy",
      checkers: [true, false, false],
    },
    {
      company: "Dell",
      checkers: [true, true, true],
    },
    {
      company: "Ebay Foundation",
      checkers: [true, false, true],
    },
    {
      company: "Fannie Mae",
      checkers: [false, false, true],
    },
    {
      company: "Federal Home Mortgage Loan (Your Cause)",
      checkers: [false, false, true],
    },
    {
      company: "Fidelity",
      checkers: [true, true, true],
    },
    // {
    //   company: "GlaxoSmithKline)",
    //   checkers: [false, false, false],
    // },
    {
      company: "Goldman Sachs",
      checkers: [true, false, false],
    },
    {
      company: "Google Inc.",
      checkers: [false, true, true],
    },
    {
      company: "Google(volunteer hours)",
      checkers: [false, true, false],
    },
    {
      company: "HPI",
      checkers: [false, false, true],
    },
    {
      company: "IBM",
      checkers: [false, false, true],
    },
    {
      company: "IMF",
      checkers: [false, false, true],
    },
    {
      company: "Intel (Benevity)",
      checkers: [true, true, true],
    },
    {
      company: "JP Morgan Chase",
      checkers: [true, false, true],
    },
    {
      company: "LinkedIn",
      checkers: [true, false, true],
    },
    {
      company: "LPL Financial",
      checkers: [true, false, false],
    },
    {
      company: "Massachusetts Mutual Life Insurance Company",
      checkers: [false, false, true],
    },
    {
      company: "Microsoft",
      checkers: [false, true, true],
    },
    {
      company: "Microsoft Volunteer Manager",
      checkers: [true, true, true],
    },
    {
      company: "Morgan Stanley",
      checkers: [false, true, false],
    },
    {
      company: "NetApp",
      checkers: [false, false, true],
    },
    {
      company: "Network for good",
      checkers: [true, false, true],
    },
    {
      company: "Oath Inc.",
      checkers: [false, false, true],
    },
    {
      company: "Oracle",
      checkers: [false, true, true],
    },
    {
      company: "Oracle (benevity)",
      checkers: [false, true, true],
    },
    {
      company: "Palo Alto Networks",
      checkers: [false, false, true],
    },
    {
      company: "Prudential",
      checkers: [true, false, false],
    },
    {
      company: "Qualcomm",
      checkers: [false, true, true],
    },
    {
      company: "Red Hat Matching Gifts",
      checkers: [true, false, true],
    },
    {
      company: "Roche Sequencing Solutions",
      checkers: [false, false, true],
    },
    {
      company: "Salesforce System",
      checkers: [false, false, true],
    },
    {
      company: "SAP Software Solutions Inc.,",
      checkers: [false, true, true],
    },
    {
      company: "State Farm Companies",
      checkers: [true, false, false],
    },
    {
      company: "Synopsys",
      checkers: [false, false, true],
    },
    {
      company: "TeraData",
      checkers: [false, false, true],
    },
    {
      company: "Thomson Reuters",
      checkers: [true, false, false],
    },
    {
      company: "UBS",
      checkers: [false, false, true],
    },
    {
      company: "USB Wealth Management",
      checkers: [true, false, true],
    },
    {
      company: "United Way (all)",
      checkers: [false, false, true],
    },
    {
      company: "United Way California Capital Region",
      checkers: [true, false, true],
    },
    {
      company: "United Way Silicon Valley",
      checkers: [true, false, true],
    },
    {
      company: "USAA",
      checkers: [false, true, true],
    },
    {
      company: "Verizon Foundation",
      checkers: [true, true, true],
    },
    {
      company: "Verizonmedia",
      checkers: [false, true, true],
    },
    {
      company: "VISA",
      checkers: [true, false, true],
    },
    {
      company: "VMWare",
      checkers: [true, false, true],
    },
    {
      company: "Wells Fargo",
      checkers: [false, false, true],
    },
    {
      company: "Wells Fargo Community Support Campaign",
      checkers: [true, true, true],
    },
    {
      company: "Workday Inc",
      checkers: [false, true, true],
    },
    {
      company: "YAHOO Inc.",
      checkers: [true, true, true],
    },
    {
      company: "Your Cause",
      checkers: [true, true, true],
    },
  ],
};

module.exports = staticStrings;
