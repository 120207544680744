/** @jsx jsx */
import { jsx } from "theme-ui";
import staticStrings from "../../data/staticStrings";
import LayoutHome from "../layout/LayoutHome";

const tableHeader = [
  "S.NO",
  "Company",
  "SRCM",
  "SMSF",
  "Heartfulness Institute",
];
const CoperateGiftMatching = () => {
  return (
    <LayoutHome seoTitle="Corporate Gift Matching - Donations">
      <table
        sx={{
          border: "1px solid #808080ba",
          margin: "20px auto",
          maxWidth: "100%",
        }}
      >
        <thead sx={{ fontWeight: "bold" }}>
          <tr>
            {tableHeader.map((item) => (
              <td key={item}>{item}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {staticStrings.coperateGiftMatching.map((items, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{items.company}</td>
              <td sx={{ textAlign: "center" }}>{items.checkers[0] && "✔"}</td>
              <td sx={{ textAlign: "center" }}>{items.checkers[1] && "✔"}</td>
              <td sx={{ textAlign: "center" }}>{items.checkers[2] && "✔"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </LayoutHome>
  );
};

export default CoperateGiftMatching;
